import React from "react";

function TabellaOrariAdulti() {
    return(
        <div className="table-container">
            <table className="table table-bordered m-auto" style={{width: "90%"}}>
                <thead>
                <tr>
                    <th className="header-cell" scope="col">Lun</th>
                    <th className="header-cell" scope="col">Mar</th>
                    <th className="header-cell" scope="col">Mer</th>
                    <th className="header-cell" scope="col">Gio</th>
                    <th className="header-cell" scope="col">Ven</th>
                    <th className="header-cell" scope="col" style={{width: "5%"}}>Fascia Prezzi</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="data-cell"></td>
                    <td className="data-cell data-mv">9:15 - 10:00</td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell data-mv">9:15 - 10:00</td>
                    <td className="data-cell fascia-a">Mattino</td>
                </tr>
                <tr>
                    <td className="data-cell"></td>
                    <td className="data-cell data-mv">10:00 - 10:45</td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell data-mv">10:00 - 10:45</td>
                    <td className="data-cell fascia-a">Mattino</td>
                </tr>
                <tr>
                    <td className="data-cell"></td>
                    <td className="data-cell data-mv">10:45 - 11:30</td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell data-mv">10:45 - 11:30</td>
                    <td className="data-cell fascia-a">Mattino</td>
                </tr>
                <br/>
                <tr>
                    <td className="data-cell"></td>
                    <td className="data-cell data-mv">12:45 - 13:30</td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                    <td className="data-cell data-mv">12:45 - 13:30</td>
                    <td className="data-cell fascia-b">Intervallo-Pomeriggio</td>
                </tr>
                <tr>
                    <td className="data-cell data-lg">16:15 - 17:00</td>
                    <td className="data-cell data-mv">16:15 - 17:00</td>
                    <td className="data-cell"></td>
                    <td className="data-cell data-lg">16:15 - 17:00</td>
                    <td className="data-cell data-mv">16:15 - 17:00</td>
                    <td className="data-cell fascia-b">Intervallo-Pomeriggio</td>
                </tr>
                <br/>
                <tr>
                    <td className="data-cell data-lg">19.15 - 20:00</td>
                    <td className="data-cell data-mv">19.15 - 20:00</td>
                    <td className="data-cell"></td>
                    <td className="data-cell data-lg">19.15 - 20:00</td>
                    <td className="data-cell data-mv">19.15 - 20:00</td>
                    <td className="data-cell fascia-c">Sera</td>
                </tr>
                <tr>
                    <td className="data-cell data-lg">20:00 - 20:45</td>
                    <td className="data-cell data-mv">20:00 - 20:45</td>
                    <td className="data-cell"></td>
                    <td className="data-cell data-lg">20:00 - 20:45</td>
                    <td className="data-cell data-mv">20:00 - 20:45</td>
                    <td className="data-cell fascia-c">Sera</td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default TabellaOrariAdulti